<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Tipo do comunicado" label-for="type">
            <v-select
              v-model="type"
              :options="announcementTypes"
              :reduce="(types) => types.id"
              label="name"
              dir="ltr"
              :class="getSelectErrorClass(v$.type.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.type.$error">
                Você deve informar o tipo do comunicado
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label="Os usuários devem ser notificados?"
            label-for="notify-users"
          >
            <v-select
              v-model="notify_users"
              :options="assertBooleanOptions"
              :reduce="(options) => options.value"
              label="name"
              dir="ltr"
              :class="getSelectErrorClass(v$.notify_users.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.notify_users.$error">
                Você deve informar se os usuários serão notificados
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col v-if="type === systemVersionType" cols="12" md="6">
          <b-form-group label="Versão" label-for="version">
            <b-form-input
              id="version"
              placeholder="Ex.: 1.0.0"
              v-model="version"
              :class="{ 'is-invalid': v$.version.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.version.required.$invalid">
                Você deve informar uma versão
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col v-if="type === callToActionType" cols="12" md="6">
          <b-form-group
            label="Título do botão de ação"
            label-for="call_to_action_button_title"
          >
            <b-form-input
              id="call_to_action_button_title"
              placeholder="Ex.: Acesse o formulário aqui!"
              v-model="call_to_action_button_title"
              :class="{ 'is-invalid': v$.call_to_action_button_title.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.call_to_action_button_title.required.$invalid">
                Você deve informar um título
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col v-if="type === callToActionType" cols="12" md="6">
          <b-form-group
            label="Link do botão de ação"
            label-for="call_to_action_link"
          >
            <b-form-input
              id="call_to_action_link"
              v-model="call_to_action_link"
              :class="{ 'is-invalid': v$.call_to_action_link.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.call_to_action_link.required.$invalid">
                Você deve informar um link
              </span>
              <span v-if="v$.call_to_action_link.url.$invalid">
                Você deve informar um link válido
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" style="margin-bottom: 90px">
          <b-form-group label="Descrição" label-for="description">
            <vue-editor
              id="description"
              v-model="description"
              :class="{ 'is-invalid': v$.description.$error }"
            />
            <div class="invalid-feedback" style="margin-top: 90px">
              <span v-if="v$.description.required.$invalid">
                Você deve informar uma descrição
              </span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row class="justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'announcements-list' }"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mx-1"
              :disabled="loading || saving"
              @click.prevent="save"
            >
              <b-spinner v-if="saving" small />
              <feather-icon v-else icon="SaveIcon" class="mr-50" />
              <span>
                {{ saving ? "Salvando..." : "Salvar" }}
              </span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueEditor } from "vue2-editor";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, url } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from "../store/types";
import { assertBooleanOptions } from "@/constants/assertions";
import { SYSTEM_VERSION, CALL_TO_ACTION } from "@/constants/announcement_types";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      saving: false,
      type: undefined,
      notify_users: undefined,
      version: undefined,
      call_to_action_link: undefined,
      call_to_action_button_title: undefined,
      description: undefined,
      assertBooleanOptions,
    };
  },
  computed: {
    ...mapGetters({
      announcementTypes: types.ANNOUNCEMENT_TYPES,
    }),
    systemVersionType() {
      return SYSTEM_VERSION;
    },
    callToActionType() {
      return CALL_TO_ACTION;
    },
  },
  validations() {
    return {
      type: { required },
      notify_users: { required },
      version: {
        required: requiredIf((value) => {
          if (this.type === SYSTEM_VERSION) {
            return true;
          }
          return false;
        }),
      },
      call_to_action_link: {
        required: requiredIf((value) => {
          if (this.type === CALL_TO_ACTION) {
            return true;
          }
          return false;
        }),
        url,
      },
      call_to_action_button_title: {
        required: requiredIf((value) => {
          if (this.type === CALL_TO_ACTION) {
            return true;
          }
          return false;
        }),
      },
      description: { required },
    };
  },
  mounted() {
    this.getAnnouncementTypes().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao carregar os tipos comunicados. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    if (this.$route.params.id) {
      this.getVersionById(this.$route.params.id)
        .then((response) => {
          const {
            type,
            notify_users,
            version,
            call_to_action_link,
            call_to_action_button_title,
            description,
          } = response.data;
          this.type = type;
          this.notify_users = notify_users ? true : false;
          this.version = version;
          this.call_to_action_link = call_to_action_link;
          this.call_to_action_button_title = call_to_action_button_title;
          this.description = description;
          this.v$.$touch();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar a versão selecionada. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    ...mapActions({
      saveVersion: types.SAVE_VERSION,
      getVersionById: types.GET_VERSION,
      getAnnouncementTypes: types.GET_ANNOUNCEMENT_TYPES,
    }),
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.saving = true;
      const {
        type,
        notify_users,
        version,
        call_to_action_link,
        call_to_action_button_title,
        description,
      } = this;
      let payload = {
        id: this.$route.params.id,
        type,
        notify_users,
        version,
        call_to_action_link,
        call_to_action_button_title,
        description,
      };
      this.saveVersion(payload)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: `A versão foi ${
                this.$route.params.id ? "atualizada" : "criada"
              } com sucesso`,
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.$router.push({ name: "announcements-list" });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar a versão. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
  },
};
</script>
